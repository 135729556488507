import Api from "./Api";

//Get Logged In User

//Country
export const AddUpdateCountryService = async (payload) =>
  Api().post(`/admin/add-edit-countries`, payload);

export const GetCountryListService = async (params) =>
  Api().get(`/admin/getcountrylist`, { params });

//City
export const AddUpdateCityService = async (payload) =>
  Api().post(`/admin/add-edit-cities`, payload);

export const GetCityListService = async (params) =>
  Api().get(`/admin/getcitylist`, { params });

//Genre
export const AddUpdateGenreService = async (payload) =>
  Api().post(`/admin/add-edit-genres`, payload);

export const GetGenreListService = async (params) =>
  Api().get(`/admin/getgenreslist`, { params });

//Language
export const AddUpdateLanguageService = async (payload) =>
  Api().post(`/admin/add-edit-languages`, payload);

export const GetLanguageListService = async (params) =>
  Api().get(`/admin/getlanguageslist`, { params });

//Seat Types
export const AddUpdateSeatTypeService = async (payload) =>
  Api().post(`/admin/add-edit-seattype`, payload);

export const GetSeatTypeListService = async (params) =>
  Api().get(`/admin/getseattypelist`, { params });

//Currency
export const AddUpdateCurrencyService = async (payload) =>
  Api().post(`/admin/add-edit-currency`, payload);

export const GetCurrencyListService = async (params) =>
  Api().get(`/admin/getcurrencylist`, { params });

//Cinema

export const AddUpdateCinemaService = async (payload) =>
  Api().post(`/admin/add-edit-cinema`, payload);

export const GetCinemaListService = async (params) =>
  Api().get(`/admin/getcinemalist`, { params });

//TimeZone

export const GetTimeZoneListService = async (params) =>
  Api().get(`/admin/gettimezonelist`, { params });

//Event

export const AddUpdateEventService = async (payload) =>
  Api().post(`/admin/add-edit-event`, payload);

export const GetEventListService = async (params) =>
  Api().get(`/admin/getEventList`, { params });

//Banners

export const GetBannerListService = async (params) =>
  Api().get(`/admin/getbannerlist`, { params });
export const AddUpdateBannerService = async (payload) =>
  Api().post(`/admin/add-edit-banner`, payload);

// Seat Layout

export const GetSeatLayoutsListService = async (params) =>
  Api().get(`/admin/getSeatLayoutList`, { params });

//Reports
export const GetTransactionListService = async (params) =>
  Api().get(`/admin/getTransactionList`, { params });

export const GetReservationListService = async (params) =>
  Api().get(`/admin/getReservationBookingList`, { params });

export const ResendEmailTicketService = async (payload) =>
  Api().post(`/admin/resend-ticket-customer`, payload);

// Event extra info

export const AddUpdateEventExtraService = async (payload) =>
  Api().post(`/admin/add-edit-eventExtra`, payload);

export const GetEventExtraInfoListService = async (params) =>
  Api().get(`/admin/get-event-extraInfoList`, { params });

//User

export const AddUpdateUserService = async (payload) =>
  Api().post(`/admin/add-edit-users`, payload);

export const GetUserListService = async (params) =>
  Api().get(`/admin/getuserlist`, { params });

//Roles

export const GetRolesListService = async (params) =>
  Api().get(`/admin/getroleslist`, { params });

export const AddUpdateRoleService = async (payload) =>
  Api().post(`/admin/add-edit-roles`, payload);

//Voucher

export const AddUpdateVoucherService = async (payload) =>
  Api().post(`/admin/add-edit-vouchers`, payload);

export const GetVoucherListService = async (params) =>
  Api().get(`/admin/getVoucherList`, { params });

export const GetSeatLayoutService = async (params) =>
  Api().get(`/admin/getSeatLayoutList`, { params });

// Blocked Seats

export const GetEventBlockedSeatsService = async (params) =>
  Api().get(
    `admin/getEventBlockedSeats/${params.event_id}/${params.event_sch_id}`
  );

export const AddEditBlockedSeatsService = async (payload) =>
  Api().post(`admin/add-edit-blockseats`, payload);

//contact us & subscribers
export const GetContactUsListService = async (params) =>
  Api().get(`admin/getContactUsList`, { params });

//org
export const GetOrganizationListService = async (params) =>
  Api().get(`admin/getOrgList`, { params });

export const AddUpdateOrgService = async (payload) =>
  Api().post(`admin/add-edit-org`, payload);

export const AddUpdateOrgWebsiteService = async (payload) =>
  Api().post(`admin/add-edit-orgwebsite`, payload);

//Home
export const GetEventDataService = async (params) =>
  Api().get(`admin/getEventHomeDataById`, { params });

//Pass

export const AddUpdatePassService = async (payload) =>
  Api().post(`admin/add-edit-pass`, payload);

export const GetPassListService = async (params) =>
  Api().get(`admin/getPassList`, { params });
